<template>
  <div>
    <b-row>
      <b-col md="4">
        <b-form-group :label="$t('general.title')">
          <b-form-input v-model="question.title" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-form-group :label="$t('tests.test_conditions')+':'">
      <quill-editor
        v-model="question.condition"
        class="mb-1"
      />
    </b-form-group>
    <hr>
    <b-card
      v-for="(q_item, index) in question.body"
      :key="index"
    >
      <div class="d-flex justify-content-between align-items-center">
        <h3 class="mb-0">
          {{ index + 1 }}.
        </h3>
        <b-button
          variant="danger"
          size="sm"
          class="p-50"
          @click="removeItem(index)"
        >
          <feather-icon icon="XIcon" />
        </b-button>
      </div>
      <hr>
      <b-form-group :label="$t('tests.test_conditions')+':'">
        <quill-editor
          v-model="q_item.task"
          class="mb-1"
        />
      </b-form-group>
      <b-form-group
        v-if="['s', 'm', 'a'].includes(question.type)"
        id="test-base-question"
        :label="$t('tests.answer_options')+':'"
      >
        <!-- <b-form-radio
          v-for="(answer_option, ind) in q_item.options"
          :key="ind"
          v-model="question.answer[index]"
          :value="answer_option.key"
          class="mt-1"
          @input="markCorrectAnswer(index, ind)"
        >
          <b-form-input
            v-model="answer_option.content"
          />
          <b-button
            variant="danger"
            class="ml-1"
            @click="removeOption(index, ind)"
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </b-form-radio> -->
        <component
          :is="getTypeComponent"
          v-model="question.answer[index]"
          stacked
        >
          <template
            v-if="question.type === 's' || question.type === 'a'"
          >
            <single-choice-type-form
              v-for="(answer_option, ind) in q_item.options"
              :key="ind"
              :option="answer_option"
              @removeOption="removeOption(index, ind)"
            />
          </template>
          <template
            v-else-if="question.type === 'm'"
          >
            <multiple-choice-type-form
              v-for="(answer_option, ind) in q_item.options"
              :key="ind"
              :option="answer_option"
              @removeOption="removeOption(index, ind)"
            />
          </template>
        </component>
        <b-button
          class="mt-1"
          variant="success"
          @click="addNewOption(index, q_item.options.length)"
        >
          <feather-icon icon="PlusIcon" />
          {{ $t('tests.add_answer_option') }}
        </b-button>
      </b-form-group>
      <b-form-group
        v-else-if="question.type === 'g'"
        :label="$t('tests.answers')+':'"
      >
        <div
          v-for="(answer, idx) in question.answer"
          :key="idx"
          class="d-flex mt-1"
        >
          <b-form-input
            v-model="question.answer[idx]"
          />
          <b-button
            class="ml-1"
            variant="danger"
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </div>
        <b-button
          class="mt-2"
          variant="success"
          @click="addCorrectAnswer"
        >
          {{ $t('tests.add_correct_answer') }}
        </b-button>
      </b-form-group>
    </b-card>
    <b-button
      variant="warning"
      @click="addNewQuestion"
    >
      <feather-icon icon="PlusIcon" />
      {{ $t('tests.add_new_question') }}
    </b-button>
    <hr>
    <div class="mt-1 d-flex justify-content-end">
      <b-button
        variant="secondary"
        class="mr-1"
        @click="preview"
      >
        {{ $t('tests.preview') }}
      </b-button>
      <b-button
        variant="primary"
        @click="saveClick"
      >
        {{ $t('general.save') }}
      </b-button>
    </div>
    <b-sidebar
      :key="increment"
      v-model="preview_sidebar"
      width="100vw"
    >
      <test-preview :questions-body="question" />
    </b-sidebar>
  </div>
</template>

<script>
import {
  BFormGroup, BRow, BCol, BFormInput, BButton, BCard, BFormRadioGroup, BFormCheckboxGroup, BSidebar,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import { quillEditor } from 'vue-quill-editor'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import singleChoiceTypeForm from './components/singleChoiceTypeForm.vue'
import multipleChoiceTypeForm from './components/multipleChoiceTypeForm.vue'
import gapFillingTypeView from './components/gapFillingTypeView.vue'
import testPreview from './components/testPreview.vue'
import dynamic from './components/dynamic.vue'

export default {
  components: {
    quillEditor,
    BFormGroup,
    BRow,
    BCol,
    BFormInput,
    // BFormRadio,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BCard,
    BSidebar,
    singleChoiceTypeForm,
    multipleChoiceTypeForm,
    gapFillingTypeView,
    testPreview,
    dynamic,
  },
  props: {
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      increment: 999,
      preview_sidebar: false,
      question: {
        title: '',
        condition: '',
        body: [
          {
            task: '',
            options: [
            ],
          },
        ],
        answer: [],
        type: this.$route.params.type,
        folder: Number(this.$route.params.folder_id) || null,
      },
    }
  },
  computed: {
    getTypeComponent() {
      // console.log(this.$data.$_test_type_options.find(el => el.value === this.$route.params.type).component)
      return this.$data.$_test_type_options.find(el => el.value === this.question.type).component
    },
    getQuestionsCount() {
      if (this.question.type === 'g') { return this.question.body[0].task.match(/@question_gap/g) }
      return []
    },
  },
  mounted() {
    if (this.isEdit) {
      this.FETCH_ONE_QUESTION(this.$route.params.id).then(resp => {
        this.question = resp
      })
    }
  },
  methods: {
    ...mapActions('questions', ['ADD_QUESTION', 'EDIT_QUESTION', 'FETCH_ONE_QUESTION']),
    addNewOption(index, incr) {
      this.question.body[index].options.push({ key: incr + 1, content: '' })
    },
    removeOption(index, ind) {
      this.question.body[index].options.splice(ind, 1)
    },
    removeItem(index) {
      this.question.body.splice(index, 1)
    },
    addNewQuestion() {
      this.question.body.push({
        task: '',
        options: [
        ],
      })
    },
    addCorrectAnswer() {
      this.question.answer.push('')
    },
    saveClick() {
      if (this.isEdit) {
        this.EDIT_QUESTION(this.question).then(() => {
          this.$_successToast(this.$t('general.edited'))
          this.$router.push({ name: 'test-base' })
        })
      } else {
        this.ADD_QUESTION(this.question).then(() => {
          this.$_successToast(this.$t('general.added'))
          this.$router.push({ name: 'test-base' })
        })
      }
    },
    preview() {
      this.increment = 1000
      this.preview_sidebar = true
    },
    // markCorrectAnswer(index, ind) {
    //   // this.question.body[index].options[ind].is_correct = true
    // },
  },
}
</script>

<style lang="scss">
#test-base-question .custom-control-label {
  display: flex !important;
}

</style>
