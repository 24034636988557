<template>
  <div class="mx-2">
    <div v-html="questionsBody.condition" />
    <hr>
    <div v-if="questionsBody.type === 'g'">
      <component
        :is="getQuestionBody()"
        @results="result"
      />
    </div>
    <div v-else-if="questionsBody.type === 's'">
      <div
        v-for="(question, index) in questionsBody.body"
        :key="index"
      >
        <!-- <b>{{ index + 1 }}. </b> -->
        <single-choice-type-view
          v-model="answers[index]"
          :question="question"
        />
      </div>
    </div>
    <div v-else-if="questionsBody.type === 'm'">
      <div
        v-for="(question, index) in questionsBody.body"
        :key="index"
      >
        <!-- <b>{{ index + 1 }}. </b> -->
        <multiple-choice-type-view
          v-model="answers[index]"
          :question="question"
        />
      </div>
    </div>
    <div v-else-if="questionsBody.type === 'a'">
      <div
        v-for="(question, index) in questionsBody.body"
        :key="index"
      >
        <!-- <b>{{ index + 1 }}. </b> -->
        <arbitrary-choice-type-view
          v-model="answers[index]"
          :question="question"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import gapFillingTypeView from './gapFillingTypeView.vue'
import singleChoiceTypeView from './singleChoiceTypeView.vue'
import multipleChoiceTypeView from './multipleChoiceTypeView.vue'
import arbitraryChoiceTypeView from './arbitraryChoiceTypeView.vue'

Vue.component('gap-filling-type-view', gapFillingTypeView)

export default {
  components: {
    gapFillingTypeView,
    singleChoiceTypeView,
    multipleChoiceTypeView,
    arbitraryChoiceTypeView,
  },
  props: {
    questionsBody: {
      type: [String, Object],
      required: true,
    },
  },
  data() {
    return {
      tasks: '',
      answers: [],
    }
  },

  methods: {
    getQuestionBody() {
      const regex = /@question_gap/g
      const regForReplace = /@question_gap/u
      const tasks = this.questionsBody.body[0].task
      const question_matches = this.questionsBody.body[0].task.match(regex)
      let html = ''
      if (this.questionsBody.type === 'g') {
        let repData = tasks
        question_matches.forEach((element, index) => {
          const a = repData.replace(
            regForReplace,
            `<gap-filling-type-view v-model="val[${index}]" @input="input"/>`,
          )
          repData = a
        })
        html = repData
      }
      return {
        template: `<div>${html}</div>`,
        props: {
          value: {
            type: Array,
            default: () => [],
          },
        },
        data() {
          return {
            val: this.value,
          }
        },
        methods: {
          input() {
            this.$emit('results', this.val)
          },
        },
      }
    },

    result(e) {
      this.answers = e
    },
  },
}
</script>

<style></style>
