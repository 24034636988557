<template>
  <div>
    <div v-html="question.task" />
    <b-form-group>
      <b-form-radio-group
        v-model="content"
        @input="$emit('input', content)"
      >
        <ol>
          <li
            v-for="(option, index) in question.options"
            :key="index"
          >
            <b-form-radio
              :value="option.key"
            >
              {{ option.content }}
            </b-form-radio>
          </li>
        </ol>
      </b-form-radio-group>
    </b-form-group>
  </div>
</template>

<script>
import { BFormGroup, BFormRadioGroup, BFormRadio } from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
    checked: {
      type: null,
      required: true,
    },
  },
  data() {
    return {
      content: this.checked,
    }
  },
}
</script>

<style>

</style>
