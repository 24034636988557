<template>
  <b-form-checkbox
    :value="option.key"
    class="mt-1"
  >
    <b-form-input
      v-model="option.content"
      @input="$emit('contentChange', option.content)"
    />
    <b-button
      variant="danger"
      class="ml-1"
      @click="$emit('removeOption')"
    >
      <feather-icon icon="XIcon" />
    </b-button>
  </b-form-checkbox>
</template>

<script>
import {
  BFormCheckbox, BFormInput, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BFormCheckbox,
    BFormInput,
    BButton,
  },
  props: {
    checked: {
      type: null,
      required: false,
    },
    option: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      check: this.checked,
      selected: null,
    }
  },
}
</script>

<style>

</style>
