<template>
  <div class="d-flex align-items-center">
    <div v-html="question.task" />
    <v-select
      v-model="content"
      :options="question.options"
      label="content"
      :reduce="opt => opt.key"
      style="min-width: 150px"
      @input="$emit('input', content)"
    />
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
    value: {
      type: null,
      required: true,
    },
  },
  data() {
    return {
      content: this.value,
    }
  },
}
</script>

<style>

</style>
